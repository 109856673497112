<template>
  <div>
    <Assistant_teacher_bar />
    <v-container fluid>
      <base-material-card
        icon="mdi-calendar"
        title="รอบบรรจุ : การแต่งตั้งคณะกรรมการประเมิน ตำแหน่งครูผู้ช่วย"
        class="px-5 py-3"
      >
        <v-card>
          <v-row>
            <v-col cols="12" md="12" class="text-center">
              <v-chip class="ma-2" color="pink" label text-color="white">
                <v-icon left>
                  mdi-account-multiple-check
                </v-icon>
                เรียกรายงานตัว {{ countData.countsPerson }}
              </v-chip>

              <v-chip class="ma-2" color="success" label text-color="white">
                <v-icon left>
                  mdi-account-group
                </v-icon>
                ปัจจุบัน {{ countData.countsPersonMain }}
              </v-chip>

              <v-chip class="ma-2" color="warning" label text-color="white">
                <v-icon left>
                  mdi-account-multiple-minus
                </v-icon>
                ออก {{ countData.total }}
              </v-chip>
              <v-btn
                @click="updateassistantcommitteequalified()"
                small
                outlined
                color="success"
              >
                <v-icon>mdi-update</v-icon> Update รายชื่อกรรมการ
              </v-btn>
            </v-col>
            <v-col cols="12" md="12">
              <v-data-table
                :headers="headers"
                :search="search"
                :items="manage_assistant_teachers"
                :loading="loading"
              >
                <template v-slot:top>
                  <div>
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="ค้นหา"
                      single-line
                      hide-details
                      filled
                    ></v-text-field>
                  </div>
                </template>
                <template v-slot:item.actions="{ item }">
                  <div>
                    <v-btn
                      color="purple"
                      small
                      fab
                      :to="
                        '/admin/personnel_temporary_assisstant/?appoin_day=' +
                          item.mt_appoin_day +
                          '&appoin_month=' +
                          item.mt_appoin_month +
                          '&appoin_year=' +
                          item.mt_appoin_year
                      "
                      ><v-icon>mdi-account-circle</v-icon></v-btn
                    >
                  </div>
                </template>

                <template v-slot:item.actions2="{ item }">
                  <div>
                    <v-btn
                      color="#827717"
                      small
                      fab
                      :to="
                        '/admin/assistant_teacher_report/manage_assistant_teacher_committee/?appoin_day=' +
                          item.mt_appoin_day +
                          '&appoin_month=' +
                          item.mt_appoin_month +
                          '&appoin_year=' +
                          item.mt_appoin_year
                      "
                      ><v-icon>mdi-numeric-2-box</v-icon></v-btn
                    >
                  </div>
                </template>

                <template v-slot:item.actions3="{ item }">
                  <div>
                    <v-btn
                      color="pink"
                      small
                      fab
                      :to="
                        '/admin/assistant_teacher/?appoin_day=' +
                          item.mt_appoin_day +
                          '&appoin_month=' +
                          item.mt_appoin_month +
                          '&appoin_year=' +
                          item.mt_appoin_year
                      "
                      ><v-icon>mdi-numeric-3-box</v-icon></v-btn
                    >
                  </div>
                </template>

                <template v-slot:item.actions4="{ item }">
                  <div>
                    <v-btn
                      color="info"
                      small
                      fab
                      :to="
                        '/admin/assistant_teacher_detail/?appoin_day=' +
                          item.mt_appoin_day +
                          '&appoin_month=' +
                          item.mt_appoin_month +
                          '&appoin_year=' +
                          item.mt_appoin_year
                      "
                      ><v-icon>mdi-numeric-4-box</v-icon></v-btn
                    >
                  </div>
                </template>

                <template v-slot:item.actions5="{ item }">
                  <div>
                    <v-btn
                      color="#1B5E20"
                      small
                      fab
                      :to="
                        '/admin/assistant_teacher_reports/?appoin_day=' +
                          item.mt_appoin_day +
                          '&appoin_month=' +
                          item.mt_appoin_month +
                          '&appoin_year=' +
                          item.mt_appoin_year
                      "
                      ><v-icon>mdi-numeric-4-box</v-icon></v-btn
                    >
                  </div>
                </template>

                <template v-slot:item.mt_type_personnel="{ item }">
                  <div>
                    <v-chip
                      color="info"
                      v-if="item.mt_type_personnel === 'recruit'"
                      >รอบแข่งขัน</v-chip
                    >
                    <v-chip
                      color="accent"
                      v-else-if="item.mt_type_personnel === 'select'"
                      >รอบคัดเลือก</v-chip
                    >
                    <v-chip
                      color="deep"
                      dark
                      v-else-if="item.mt_type_personnel === 'transfer_position'"
                      >รอบโอน</v-chip
                    >
                    <v-chip
                      color="secondary"
                      v-else-if="item.mt_type_personnel === 'local_dev'"
                      >รอบคืนถิ่น</v-chip
                    >
                  </div>
                </template>

                <template v-slot:item.clearData="{ item }">
                  <div>
                    <v-btn
                      fab
                      small
                      outlined
                      color="red"
                      @click="
                        clearData(
                          item.mt_appoin_day,
                          item.mt_appoin_month,
                          item.mt_appoin_year
                        )
                      "
                      ><v-icon>mdi-notification-clear-all</v-icon></v-btn
                    >
                  </div>
                </template>

                <template v-slot:item.action="{ item }">
                  <div>
                    <v-btn
                      fab
                      small
                      rounded
                      color="warning"
                      @click="
                        settingTypePersonnel(
                          item.mt_appoin_day,
                          item.mt_appoin_month,
                          item.mt_appoin_year
                        )
                      "
                    >
                      <v-icon>mdi-tooltip-edit</v-icon></v-btn
                    >
                  </div>
                </template>

                <template v-slot:item.pertem_assistantFileIpa="{ item }">
                  <div v-if="item.pertem_assistantFileIpastatus === 'notice'">
                    <v-btn
                      @click="
                        viewpertem_assistantFileIpa(
                          item.pertem_assistantFileIpa
                        )
                      "
                      icon
                      color="info"
                    >
                      <v-icon>mdi-printer</v-icon></v-btn
                    >
                  </div>
                </template>

                <template v-slot:item.month6="{ item }">
                  <div>
                    {{ item.month6 | moment("D MMMM YYYY") }}
                  </div>
                </template>

                <template v-slot:item.month12="{ item }">
                  <div>
                    {{ item.month12 | moment("D MMMM YYYY") }}
                  </div>
                </template>

                <template v-slot:item.month18="{ item }">
                  <div>
                    {{ item.month18 | moment("D MMMM YYYY") }}
                  </div>
                </template>

                <template v-slot:item.month24="{ item }">
                  <div>
                    {{ item.month24 | moment("D MMMM YYYY") }}
                  </div>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </base-material-card>

      <!--updateTypePersonneldialog  -->
      <v-layout row justify-center>
        <v-dialog
          v-model="updateTypePersonneldialog"
          persistent
          max-width="50%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              icon="mdi-account-multiple"
              title="ปรับปรุงข้อมูลการบรรจุ"
              class="px-5 py-3 text_google"
            >
            </base-material-card>

            <v-card-text>
              <v-form ref="updateTypePersonnelform" lazy-validation>
                <v-container grid-list-md>
                  <div class="text-center ma-5 font-weight-bold">
                    วันที่บรรจุ :
                    {{
                      updateTypePersonnels.mt_date_app_now
                        | moment("add", "543 year")
                        | moment("D MMMM YYYY")
                    }}
                  </div>
                  <v-layout wrap>
                    <v-flex md6>
                      <v-autocomplete
                        :items="type_personnelApp"
                        item-text="text"
                        item-value="value"
                        label="รอบการบรรจุ"
                        v-model="updateTypePersonnels.mt_type_personnel"
                        required
                        :rules="[v => !!v || '']"
                        outlined
                        prepend-icon="mdi-clock-time-eight"
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex md6>
                      <v-text-field
                        label="จำนวนบรรจุ"
                        v-model="updateTypePersonnels.mt_numfirst"
                        required
                        type="number"
                        :rules="[v => !!v || '']"
                        outlined
                        prepend-icon="mdi-account-group"
                      ></v-text-field>
                    </v-flex>

                    <v-flex md6>
                      <v-text-field
                        label="คำสั่ง"
                        v-model="updateTypePersonnels.mt_order_app"
                        required
                        :rules="[v => !!v || '']"
                        outlined
                        prepend-icon="mdi-order-alphabetical-ascending"
                      ></v-text-field>
                    </v-flex>

                    <v-flex md6>
                      <v-file-input
                        v-model="pertem_assistantFileIpa"
                        accept=".pdf"
                        name="pertem_assistantFileIpa"
                        color="deep-purple accent-4"
                        counter
                        label="ไฟล์คำสั่งแต่งตั้งคณะกรรมการ"
                        placeholder="Select your files"
                        prepend-icon="mdi-paperclip"
                        outlined
                        :show-size="1000"
                      >
                        <template v-slot:selection="{ index, text }">
                          <v-chip
                            v-if="index < 2"
                            color="deep-purple accent-4"
                            dark
                            label
                            small
                          >
                            {{ text }}
                          </v-chip>

                          <span
                            v-else-if="index === 2"
                            class="text-overline grey--text text--darken-3 mx-2"
                          >
                            +{{ files.length - 2 }} File(s)
                          </span>
                        </template>
                      </v-file-input>
                    </v-flex>

                    <v-flex md12>
                      <v-radio-group
                        v-model="
                          updateTypePersonnels.pertem_assistantFileIpastatus
                        "
                        row
                      >
                        <template v-slot:label>
                          <div><strong>สถานะคำสั่งแต่งตั้ง</strong></div>
                        </template>
                        <v-radio value="process">
                          <template v-slot:label>
                            <div>
                              <strong class="warning--text"
                                >อยู่ระหว่างดำเนินการ</strong
                              >
                            </div>
                          </template>
                        </v-radio>
                        <v-radio value="notice">
                          <template v-slot:label>
                            <div>
                              <strong class="primary--text">ประกาศ</strong>
                            </div>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </v-flex>
                  </v-layout>
                </v-container>
                <small>* จำเป็น</small>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="warning"
                outlined
                @click.stop="updateTypePersonneldialog = false"
                rounded
                ><v-icon dark>mdi-close</v-icon> ยกเลิก</v-btn
              >
              <v-btn
                color="success"
                @click.stop="updateTypePersonnelsSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model pertem_assistantFileIpadialog -->
      <v-dialog v-model="pertem_assistantFileIpadialog" max-width="80%">
        <v-card class="" elevation="2">
          <embed
            :src="'/HRCommitteeQf/' + pdffiles"
            width="100%"
            height="700px"
          />
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import Assistant_teacher_bar from "../../../components/admin/assistant_teacher_bar.vue";
export default {
  name: "HRvecPersonnelTemporaryAssistantPeriod",
  data() {
    return {
      loading: false,
      ApiKey: "HRvec2021",
      search: "",
      headers: [
        { text: "ตรวจสอบบุคคล", align: "center", value: "actions" },
        { text: "เสนอกรรมการ", align: "center", value: "actions2" },
        { text: "การประเมิน", align: "center", value: "actions3" },
        /*   { text: "การประเมิน", align: "center", value: "actions4" }, */
        { text: "รายงาน", align: "center", value: "actions5" },
        { text: "รอบวันเดือนปี", align: "center", value: "dateApps" },
        { text: "เงื่อนไข", align: "center", value: "mt_type_personnel" },
        { text: "กำหนด", align: "center", value: "action" },

        { text: "คำสั่งที่", align: "center", value: "mt_order_app" },
        {
          text: "ไฟล์/ประกาศ",
          align: "center",
          value: "pertem_assistantFileIpa"
        },
        { text: "บรรจุ จำนวน", align: "center", value: "mt_numfirst" },
        {
          text: "ปัจจุบัน (ครูผู้ช่วย)",
          align: "center",
          value: "countAssistants"
        },
        {
          text: "ปัจจุบัน (ครูผู้ช่วย หลัก)",
          align: "center",
          value: "countManage"
        },
        { text: "จัดการข้อมูล", align: "center", value: "clearData" },
        { text: "ครบ 4 ครั้ง", align: "center", value: "countSuccess" },
        { text: "กำหนด ครั้งที่ 1", align: "center", value: "month6" },
        { text: "กำหนด ครั้งที่ 2", align: "center", value: "month12" },
        { text: "กำหนด ครั้งที่ 3", align: "center", value: "month18" },
        { text: "กำหนด ครั้งที่ 4", align: "center", value: "month24" }
      ],
      manage_assistant_teachers: [],
      type_personnelApp: [
        { text: "รอบคัดเลือก", value: "select" },
        { text: "รอบแข่งข้น", value: "recruit" },
        { text: "รอบคืนถิ่น", value: "local_dev" },
        { text: "รอบโอน", value: "transfer_position" }
      ],
      updateTypePersonnels: [],
      updateTypePersonneldialog: false,
      countData: [],
      pertem_assistantFileIpa: null,
      pertem_assistantFileIpadialog: false,
      pdffiles: ""
    };
  },
  async mounted() {
    await this.manage_assistant_teacherQuery();
  },
  methods: {
    async viewpertem_assistantFileIpa(pertem_assistantFileIpa) {
      this.pdffiles = pertem_assistantFileIpa;
      this.pertem_assistantFileIpadialog = true;
    },

    async updateassistantcommitteequalified() {
      this.loading = true;
      let result = await this.$http
        .post("assistantcommitteequalified.updateToM.php")
        .finally(() => (this.loading = false));

      if (result.data.status == true) {
        Swal.fire({
          icon: "success",
          title: "ดำเนินการเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
      } else {
        Swal.fire({
          icon: "warning",
          title: "ดำเนินการผิดพลาด",
          showConfirmButton: false,
          timer: 1500
        });
      }
    },
    async clearData(mt_appoin_day, mt_appoin_month, mt_appoin_year) {
      let dataText =
        "วันที่บรรจุ :" +
        mt_appoin_day +
        "-" +
        mt_appoin_month +
        "-" +
        mt_appoin_year;
      let dataApi = {
        ApiKey: this.ApiKey,
        mt_appoin_day: mt_appoin_day,
        mt_appoin_month: mt_appoin_month,
        mt_appoin_year: mt_appoin_year
      };
      Swal.fire({
        title: "ต้องการจัดการข้อมูลให้ระบบรายงานตัวตรงกับฐานข้อมูลหลัก ? ",
        text: dataText,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          let resultClear = await this.$http.post(
            "manage_assistant_teacher.delete.whonull.php",
            dataApi
          );

          if (resultClear.data.status == true) {
            Swal.fire({
              icon: "success",
              title: "ดำเนินการเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
            await this.manage_assistant_teacherQuery();
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        }
      });
    },
    async manage_assistant_teacherQuery() {
      this.loading = true;
      let result = await this.$http
        .post("manage_assistant_teacher.php", {
          ApiKey: this.ApiKey,
          calendar_admin: "ok"
        })
        .finally(() => (this.loading = false));
      this.manage_assistant_teachers = result.data;
      let data = this.manage_assistant_teachers;
      let countsPerson = 0;
      let countsPersonMain = 0;
      data.forEach(value => {
        countsPerson += parseInt(value.countManage);
        countsPersonMain += parseInt(value.countAssistants);
      });
      this.countData.countsPerson = countsPerson;
      this.countData.countsPersonMain = countsPersonMain;
      this.countData.total = parseInt(countsPerson - countsPersonMain);
    },

    async settingTypePersonnel(mt_appoin_day, mt_appoin_month, mt_appoin_year) {
      let result = await this.$http.post("manage_assistant_teacher.php", {
        ApiKey: this.ApiKey,
        mt_appoin_day: mt_appoin_day,
        mt_appoin_month: mt_appoin_month,
        mt_appoin_year: mt_appoin_year,
        group_date: "ok"
      });
      this.updateTypePersonnels = result.data;
      

      this.updateTypePersonneldialog = true;
    },
    async updateTypePersonnelsSubmit() {
      if (this.$refs.updateTypePersonnelform.validate()) {
        this.updateTypePersonnels.ApiKey = this.ApiKey;
        let result_file = "";
        let timeapp = String(
          this.updateTypePersonnels.mt_appoin_year +
            "-" +
            this.updateTypePersonnels.mt_appoin_month +
            "-" +
            this.updateTypePersonnels.mt_appoin_day +
            "-" +
            this.updateTypePersonnels.mt_type_personnel
        );

        if (this.pertem_assistantFileIpa != null) {
          let formData = new FormData();
          let filename = timeapp + "." + this.time_stamp + "." + "order.pdf";
          formData.append("ApiKey", this.ApiKey);
          formData.append("file", this.pertem_assistantFileIpa);
          formData.append("filename", "../HRCommitteeQf/" + filename);

          result_file = await this.$http.post("uploadfile.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });
          this.updateTypePersonnels.pertem_assistantFileIpa = "";
          this.updateTypePersonnels.pertem_assistantFileIpa = filename;
        }

        let result = await this.$http.post(
          "manage_assistant_teacher.updateadmin.order.php",
          this.updateTypePersonnels
        );

        if (result.data.status == true) {
          Swal.fire({
            title: "ดำเนินการข้อมูลเรียบร้อย",
            icon: "success",
            showConfirmButton: false,
            timer: 1500
          });
          await this.manage_assistant_teacherQuery();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.updateTypePersonneldialog = false;
      }
    }
  },
  computed: {
    time_stamp() {
      const d = new Date();
      let time = Math.floor(d.getTime() / 1000);
      return time;
    }
  },
  components: { Assistant_teacher_bar }
};
</script>

<style lang="scss" scoped></style>
